.react-datepicker__navigation {
  border: solid #f1a637 !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  width: 12px !important;
  height: 12px !important;
}

.react-datepicker__navigation--previous {
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
  left: 12px !important;
}

.react-datepicker__navigation--next {
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
  right: 100px !important;
}
